<template>
  <div>
    <div class="bar">
      <a href="www.coopharma.coop">
        <img class="logoButton" src="../../assets/icons/logo-coopharma.png" />
      </a>
    </div>
    <div class="row">
      <div class="col col-2"></div>
      <div class="col col-7 text-center">
        <p
          class="h6"
          style="margin-top: -50px; color: #9b9b9b;"
          v-if="validuser"
        >
          {{ name }}
        </p>
        <p
          class="h8"
          style="margin-top: -10px; color: #9b9b9b;"
          v-if="validuser"
        >
          {{ email }}
        </p>
      </div>
      <div class="col col-3">
        <button
          style="margin-left: 30px; margin-top: -90px;"
          v-if="validuser"
          @click="signOut"
          class="btn btn-secondary"
        >
          {{ $t("barcode.exit") }}
          <!-- {{ languaje == "es" ? "Salir" : "Exit" }} -->
        </button>
      </div>
    </div>
    <div v-if="validuser == false">
      <div class="row justify-content-center mt-5">
        <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
          <form v-on:submit.prevent="signIn">
            <h5 class="h4 text-center mt-3">
              {{ $t("barcode.validUser") }}
              <!-- {{
                languaje == "es" ? "Valide su Usuario" : "Validate your User"
              }} -->
            </h5>
            <div class="row justify-content-center">
              <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="inputContainer">
                  <label for="exampleInputEmail1"></label>
                  <input
                    type="email"
                    v-if="languaje == 'es'"
                    class="form-control inputForm"
                    aria-describedby="emailHelp"
                    placeholder="Correo Electronico"
                    v-model="email"
                  />
                  <input
                    v-else
                    type="email"
                    class="form-control inputForm"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    v-model="email"
                  />
                </div>
                <div class="inputContainer">
                  <label for="exampleInputPassword1"></label>
                  <input
                    v-if="languaje == 'es'"
                    type="password"
                    class="form-control inputForm"
                    placeholder="Contraseña"
                    v-model="password"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control inputForm"
                    placeholder="Password"
                    v-model="password"
                  />
                </div>
                <button
                  style="margin-bottom:20px"
                  class="btn darkBackground lightFont registerButton2"
                  type="submit"
                >
                  {{ $t("barcode.validate") }}
                  <!-- {{ languaje == "es" ? "Validar" : "Validate" }} -->
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container" v-if="validuser == true">
      <h5 class="h4 text-center mt-3">
        {{ $t("barcode.searchProduct") }}
        <!-- {{
          languaje == "es"
            ? "Buscar Producto por Codigo de Barra"
            : "Search Product by Barcode"
        }} -->
      </h5>
      <div class="container">
        <div class="mt-4 row">
          <div class="col-2" v-if="!videoBarcode">
            <button class="btn btn-success" @click="activateBarcodeVideo">
              <i class="fas fa-barcode"></i>
            </button>
          </div>
          <div class="col-7" v-if="!videoBarcode">
            <input
              type="text"
              v-model="barcodeSearch"
              v-if="languaje == 'es'"
              class="form-control"
              id="barcodesearch"
              placeholder="Codigo de Barra"
            />
            <input
              type="text"
              v-model="barcodeSearch"
              v-else
              class="form-control"
              id="barcodesearch"
              placeholder="Barcode"
            />
          </div>
          <div class="col-9" v-if="videoBarcode">
            <button class="btn btn-danger" @click="desactivateBarcodeVideo">
              <i class="fas fa-barcode"></i>
              {{ $t("barcode.closeReader") }}
              <!-- {{ languaje == "es" ? "Cerrar Lector" : "Close Reader" }} -->
            </button>
          </div>
          <div class="col-3">
            <button @click="findByBarcode" class="btn btn-primary btn-block">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <hr />
        <v-quagga
          v-if="videoBarcode"
          :onDetected="logIt"
          :readerSize="readerSize"
          :readerTypes="['ean_reader']"
        ></v-quagga>
        <hr v-if="videoBarcode" />
        <div v-if="product">
          <div class="mt-6">
            <div class="form-group mt-4">
              <img class="productImage" :src="image" />
            </div>
            <div class="form-group mt-4">
              <label>
                {{ $t("barcode.productName") }}:
                <!-- {{
                languaje == "es" ? "Nombre de Producto" : "Product Name:"
              }}-->
              </label>
              <input
                type="text"
                v-model="productName"
                disabled
                class="form-control"
              />
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label>
                  {{ $t("barcode.productCategory") }}:
                  <!-- {{
                  languaje == "es"
                    ? "Categoria de Producto"
                    : "Product Category:"
                }} -->
                </label>
                <input
                  type="text"
                  v-model="subCategory"
                  disabled
                  class="form-control"
                />
              </div>
              <div class="form-group col-6">
                <label>
                  {{ $t("barcode.productStatus") }}:
                  <!-- {{
                  languaje == "es" ? "Estado de Producto" : "Product Status:"
                }} -->
                </label>
                <input
                  type="text"
                  v-model="productStatus"
                  disabled
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group">
              <label>
                {{ $t("barcode.productDescription") }}:
                <!-- {{
                languaje == "es"
                  ? "Descripcion de Producto"
                  : "Product Description:"
              }} -->
              </label>
              <textarea
                type="text"
                v-model="description"
                disabled
                class="form-control"
              ></textarea>
            </div>

            <div class="row">
              <div class="form-group col-6">
                <label>
                  {{
                    languaje == "es"
                      ? "Cantidad de Producto"
                      : "Product Quantity:"
                  }}</label
                >
                <input
                  type="text"
                  v-model="productQuantity"
                  disabled
                  class="form-control"
                />
              </div>
              <div class="form-group col-6">
                <label>{{ languaje == "es" ? "IVU" : "IVU:" }}</label>
                <input
                  type="text"
                  v-model="totalIVU"
                  disabled
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label>{{
                  languaje == "es" ? "Precio de Compra" : "Purchase Price:"
                }}</label>
                <input
                  type="text"
                  v-model="originalPrice"
                  disabled
                  class="form-control"
                />
              </div>
              <div class="form-group col-4">
                <label>{{
                  languaje == "es" ? "Precio de Venta" : "Sale Price:"
                }}</label>
                <input
                  type="text"
                  v-model="productPrice"
                  disabled
                  class="form-control"
                />
              </div>
              <div class="form-group col-4">
                <label>{{
                  languaje == "es" ? "Precio de Oferta" : "Offer Price:"
                }}</label>
                <input
                  type="text"
                  v-model="specialProductPrice"
                  disabled
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label>{{ languaje == "es" ? "Shopper" : "Shopper:" }}</label>
                <div class="star-rating col col-6" v-if="esShopper">
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: shopperA }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: shopperB }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: shopperC }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: shopperD }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: shopperE }"
                    >★</a
                  >
                </div>
                <div v-else>
                  <input
                    type="text"
                    v-if="languaje == 'es'"
                    value="No es Shopper"
                    disabled
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-else
                    disabled
                    value="Not Shopper"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group col-6">
                <label>{{ languaje == "es" ? "Offer" : "Offer:" }}</label>
                <div class="star-rating col col-6" v-if="esOffer">
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: offerA }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: offerB }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: offerC }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: offerD }"
                    >★</a
                  >
                  <a
                    disabled
                    class="text-warning"
                    v-bind:class="{ selected: offerE }"
                    >★</a
                  >
                </div>
                <div v-else>
                  <input
                    type="text"
                    v-if="languaje == 'es'"
                    value="No es Oferta"
                    disabled
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-else
                    value="Not Offer"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary"
            @click="agregarproducto"
            v-if="externalproduct"
          >
            {{ languaje == "es" ? "Agregar Producto" : "Add Product" }}
          </button>
        </div>
        <div class="container" v-if="!product">
          <div class="form-group mt-6">
            <h3 class="text-center">{{ aviso }}</h3>
            <button
              v-if="!videoBarcode"
              class="btn btn-primary btn-block"
              type="button"
              data-toggle="modal"
              data-target="#exampleModalCenter"
              @click="agregarProductos()"
            >
              {{ languaje == "es" ? "Agregar Producto" : "Add Product" }}
            </button>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aricoa-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div
              style="height:700px; width:65%; overflow: scroll; !important"
              class="modal-content add-product-form modalContainer"
            >
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{
                    modalStatus
                      ? languaje == "es"
                        ? "Editar Producto"
                        : "Edit Product"
                      : languaje == "es"
                      ? "Crear Producto"
                      : "Create Product"
                  }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="cerrar"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body specialBody">
                <div
                  class="col col-12 justify-content-center"
                  v-if="!modalStatus"
                >
                  <button
                    v-if="false && !modalStatus"
                    @click="changeMultiple()"
                    v-bind:class="{
                      'btn btn-primary': !multiple,
                      'btn btn-success': multiple
                    }"
                  >
                    {{
                      multiple
                        ? languaje == "es"
                          ? "Multiples Productos"
                          : "Multiple Products"
                        : languaje == "es"
                        ? "Un Producto"
                        : "One Product"
                    }}
                  </button>
                </div>
                <div class="mt-3" v-if="multiple">
                  <form>
                    <div class="form-group">
                      <label>Insert Excell File:</label>
                      <input
                        type="file"
                        accept=".xls,.xlsx"
                        class="form-control"
                        @change="proccessFile"
                        id="fileRoute"
                      />
                    </div>
                    <div class="scroll">
                      <table class="table table-hover">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">
                              {{
                                languaje == "es"
                                  ? "Numero de Familia"
                                  : "Number Family"
                              }}
                            </th>
                            <th scope="col">
                              {{ languaje == "es" ? "Nombre" : "Name" }}
                            </th>
                            <th scope="col">
                              {{ languaje == "es" ? "Supplier" : "Suplidor" }}
                            </th>
                            <th scope="col">
                              {{
                                languaje == "es"
                                  ? "Pack Size"
                                  : "Tamaño de Paquete"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, key) of dataInfo" :key="key">
                            <td>{{ data.ProductNumberFamily }}</td>
                            <td>{{ data.ProductName }}</td>
                            <td>{{ data.SupplierName }}</td>
                            <td>{{ data.PackSize }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                  <div class="Container mt-3">
                    <input
                      v-if="languaje == 'es'"
                      type="button"
                      class="btn btn-primary btn-lg btn-block"
                      :disabled="!dataInfo"
                      @click="proccessMultipleProducts"
                      value="Procesar Multiples Farmacias"
                      id="btnGuardar"
                    />
                    <input
                      v-else
                      type="button"
                      class="btn btn-primary btn-lg btn-block"
                      :disabled="!dataInfo"
                      @click="proccessMultipleProducts"
                      value="Proccess Multiple Pharmacies"
                      id="btnGuardar"
                    />
                  </div>
                </div>
                <div v-if="!multiple">
                  <div class="row justify-content-center mt-3">
                    <div class="col-12">
                      <br />
                      <div class="row justify-content-center">
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <div class="category">
                            <label class="form-label" for="exampleInputEmail1">
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                aria-hidden="true"
                              ></i>
                              {{
                                languaje == "es"
                                  ? "Nombre de Producto:"
                                  : "Product Name:"
                              }}</label
                            >
                            <input
                              v-model="productName"
                              type="text"
                              class="form-control modal-form productInput"
                              id="name"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label class="form-label" for="exampleInputEmail1">
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i>
                              {{
                                languaje == "es"
                                  ? "Precio de Venta:"
                                  : "Sale Price:"
                              }}</label
                            >
                            <div class="row">
                              <div class="col-3">
                                <button
                                  class="btn btn-danger"
                                  @click="minusproductprice"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col-6">
                                <input
                                  v-model="productPrice"
                                  type="number"
                                  id="price"
                                  class="form-control modal-form productInput"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div class="col-3">
                                <button
                                  class="btn btn-success"
                                  @click="plusproductprice"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label class="form-label" for="exampleInputEmail1">
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i>
                              {{
                                languaje == "es"
                                  ? "Precio de Compra:"
                                  : "Purchase Price:"
                              }}</label
                            >
                            <div class="row">
                              <div class="col-3">
                                <button
                                  class="btn btn-danger"
                                  @click="minusoriginalPrice"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col-6">
                                <input
                                  v-model="originalPrice"
                                  type="number"
                                  id="originalprice"
                                  class="form-control modal-form productInput"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div class="col-3">
                                <button
                                  class="btn btn-success"
                                  @click="plusoriginalPrice"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label class="form-label" for="exampleInputEmail1">
                              {{
                                languaje == "es"
                                  ? "Precio de Oferta:"
                                  : "Offer Price:"
                              }}</label
                            >
                            <div class="row">
                              <div class="col-3">
                                <button
                                  class="btn btn-danger"
                                  @click="minusspecialProductPrice"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col-6">
                                <input
                                  v-model="specialProductPrice"
                                  type="number"
                                  id="price"
                                  class="form-control modal-form productInput"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div class="col-3">
                                <button
                                  class="btn btn-success"
                                  @click="plusspecialProductPrice"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label
                              class="form-label"
                              for="exampleInputQuantity1"
                            >
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i
                              >{{
                                languaje == "es" ? " Cantidad:" : " Quantity:"
                              }}</label
                            >
                            <div class="row">
                              <div class="col-3">
                                <button
                                  class="btn btn-danger"
                                  @click="minusproductQuantity"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col-6">
                                <input
                                  v-model="productQuantity"
                                  type="number"
                                  id="productQuantity"
                                  class="form-control modal-form productInput"
                                  aria-describedby="basic-addon1"
                                  v-bind:class="{
                                    aqui: this.quantity < this.adviceQuanty
                                  }"
                                />
                              </div>
                              <div class="col-3">
                                <button
                                  class="btn btn-success"
                                  @click="plusproductQuantity"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label
                              class="form-label"
                              for="exampleInputQuantity1"
                            >
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i
                              >{{
                                languaje == "es"
                                  ? " Aviso de Poca Existencia:"
                                  : " Advice of Few Existance:"
                              }}</label
                            >
                            <div class="row">
                              <div class="col-3">
                                <button
                                  class="btn btn-danger"
                                  @click="minusadviceQuanty"
                                >
                                  <i class="fas fa-minus"></i>
                                </button>
                              </div>
                              <div class="col-6">
                                <input
                                  v-model="adviceQuanty"
                                  type="number"
                                  id="advicetQuantity"
                                  class="form-control modal-form productInput"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div class="col-3">
                                <button
                                  class="btn btn-success"
                                  @click="plusadviceQuanty"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="category">
                            <label
                              class="form-label"
                              for="exampleInputQuantity1"
                            >
                              <i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i
                              >{{
                                languaje == "es"
                                  ? " Codigo de Barras:"
                                  : " Barcode:"
                              }}</label
                            >
                            <input
                              v-model="barcode"
                              type="text"
                              id="barcode"
                              class="form-control modal-form productInput"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                        >
                          <div class="form-group">
                            <label
                              class="form-label"
                              for="exampleFormControlSelect1"
                              ><i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i>
                              {{
                                languaje == "es" ? "Categoria:" : "Category:"
                              }}</label
                            >
                            <select
                              v-model="subCategory"
                              class="form-control category productInput"
                              style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
                              id="exampleFormControlSelect1"
                            >
                              <option v-if="!subCat" class="title-category">
                                {{
                                  languaje == "es"
                                    ? "No tiene Categorías Disponibles"
                                    : "There is not enable sub-category"
                                }}
                              </option>
                              <option
                                v-for="sub in subCat"
                                :value="sub.get('subCategory')"
                                :key="sub.id"
                                class="title-category"
                              >
                                {{ sub.get("subCategory") }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2"
                      >
                        <div class="category">
                          <label class="form-label" for="exampleInputEmail1">{{
                            languaje == "es"
                              ? "Detalles del Producto:"
                              : "Product's Details:"
                          }}</label>
                          <textarea
                            v-model="description"
                            type="text"
                            id="description"
                            class="form-control modal-form productInput"
                            aria-describedby="basic-addon1"
                            cols="20"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <br />
                      <div class="row justify-content-center">
                        <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div class="category">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="isIvu"
                                @change="noAplicarIVU()"
                              />
                              <span class="slider round"></span>
                            </label>
                            <div style="margin-top: -40px; margin-left: 70px;">
                              <span>{{
                                languaje == "es"
                                  ? "No Aplicar IVU:"
                                  : "Do Not Apply IVU:"
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <div class="category mt-3" v-if="!isIvu">
                            <label class="switch">
                              <input
                                type="checkbox"
                                @change="changeCustomIvu(esCustomIvu)"
                                v-model="esCustomIvu"
                              />
                              <span class="slider round"></span>
                            </label>
                            <div style="margin-top: -50px; margin-left: 70px;">
                              <input
                                v-model="customIVU"
                                type="text"
                                id="customIVU"
                                class="form-control modal-form productInput"
                                aria-describedby="basic-addon1"
                                :disabled="!esCustomIVU"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <div class="category mt-3" v-if="!isIvu">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="isOnePercent"
                                @change="soloOneIvu()"
                              />
                              <span class="slider round"></span>
                            </label>
                            <div style="margin-top: -40px; margin-left: 70px;">
                              <span>1 %</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <div class="category mt-3" v-if="!isIvu">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="isSevenPercent"
                                @change="soloSevenIVU()"
                              />
                              <span class="slider round"></span>
                            </label>
                            <div style="margin-top: -40px; margin-left: 70px;">
                              <span>7 %</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <div class="category mt-3" v-if="!isIvu">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="isElevenPercent"
                                @change="soloElevenIVU()"
                              />
                              <span class="slider round"></span>
                            </label>
                            <div style="margin-top: -40px; margin-left: 70px;">
                              <span>11.5 %</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="row justify-content-center">
                        <div class="col-12">
                          <div class="form-group">
                            <label
                              class="form-label"
                              for="exampleFormControlSelect1"
                              ><i
                                class="fa fa-asterisk asteric"
                                v-if="!modalStatus"
                                style="size: 10px; color:#a50000ad;"
                                aria-hidden="true"
                              ></i>
                              {{
                                languaje == "es" ? "Foto:" : "Picture:"
                              }}</label
                            >
                            <br />
                            <div class="custom-file">
                              <input
                                @change="onFileSelected"
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile02"
                                accept="image/*"
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile02"
                                style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
                                accept="image/*"
                                id="imgText"
                              >
                                {{
                                  languaje == "es"
                                    ? "Selecciones una Foto:"
                                    : "Choose the picture:"
                                }}
                              </label>
                              <div class="input-group-append">
                                <div class="row">
                                  <div class="col col-10">
                                    <button
                                      v-if="picture == null"
                                      class="btn btn-outline-secondary"
                                      type="button"
                                      style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; color: white; border: 0;"
                                    >
                                      {{
                                        languaje == "es"
                                          ? "Subir una Foto"
                                          : "Upload Picture"
                                      }}
                                    </button>
                                    <button
                                      v-if="picture != null"
                                      class="btn btn-outline-secondary"
                                      type="button"
                                      style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: blue; color: white; border: 0;"
                                    >
                                      {{
                                        languaje == "es"
                                          ? "Foto Guardada"
                                          : "Saved Picture"
                                      }}
                                    </button>
                                  </div>
                                  <div class="col col-2">
                                    <button
                                      @click="proccesscamaralocal"
                                      v-bind:class="{
                                        'btn btn-danger': camaralocal,
                                        'btn btn-success': !camaralocal
                                      }"
                                    >
                                      <i class="fas fa-camera"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div id="camera" @click="takeSnapShot()"></div>
                                <div class="text-center mt-2">
                                  <input
                                    class="btn btn-info"
                                    v-if="languaje == 'es' && camaralocal"
                                    type="button"
                                    value="Capturar"
                                    id="btPic"
                                    @click="takeSnapShot()"
                                  />
                                  <input
                                    class="btn btn-info"
                                    v-if="languaje == 'en' && camaralocal"
                                    type="button"
                                    value="Capture"
                                    id="btPic"
                                    @click="takeSnapShot()"
                                  />
                                </div>
                                <div
                                  v-if="camaralocal"
                                  class="container mt-2 mb-2 text-center"
                                >
                                  <p id="snapShot"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 mt-3 row rankBox">
                        <div class="col col-6">
                          <div class="row">
                            <div class="col-6">
                              <label class="form-label">
                                Shopper
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  @change="changeShopper()"
                                  v-model="esShopper"
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          class="star-rating col col-10 mb-6"
                          v-if="esShopper"
                        >
                          <a
                            class="space star"
                            @click="onShopperA"
                            v-bind:class="{ selected: shopperA }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onShopperB"
                            v-bind:class="{ selected: shopperB }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onShopperC"
                            v-bind:class="{ selected: shopperC }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onShopperD"
                            v-bind:class="{ selected: shopperD }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onShopperE"
                            v-bind:class="{ selected: shopperE }"
                            >★</a
                          >
                        </div>
                      </div>
                      <br />
                      <div class="mb-3 mt-3 row rankBox">
                        <div class="col col-6">
                          <div class="row">
                            <div class="col-6">
                              <label class="form-label">
                                {{ languaje == "es" ? "Oferta" : "Offer" }}
                              </label>
                            </div>
                            <div class="col-6">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  @change="changeOffer()"
                                  v-model="esOffer"
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="star-rating col col-10 " v-if="esOffer">
                          <a
                            class="space star"
                            @click="onOfferA"
                            v-bind:class="{ selected: offerA }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onOfferB"
                            v-bind:class="{ selected: offerB }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onOfferC"
                            v-bind:class="{ selected: offerC }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onOfferD"
                            v-bind:class="{ selected: offerD }"
                            >★</a
                          >
                          <a
                            class="space star"
                            @click="onOfferE"
                            v-bind:class="{ selected: offerE }"
                            >★</a
                          >
                        </div>
                      </div>
                      <div class="modal-footer mt-5">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          @click="cerrar"
                          style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                        >
                          {{ languaje == "es" ? "Cerrar" : "Close" }}
                        </button>
                        <button
                          v-if="modalStatus != true"
                          @click="createProducts"
                          type="button"
                          class="btn btn-primary"
                          style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                        >
                          {{
                            languaje == "es"
                              ? "Guardar Cambios"
                              : "Save Changes"
                          }}
                        </button>
                        <button
                          v-if="modalStatus == true"
                          @click="saveChange"
                          type="button"
                          class="btn btn-primary"
                          style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #1e1e1e; border: 0;"
                        >
                          {{
                            languaje == "es"
                              ? "Guardar Cambios"
                              : "Save Changes"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueQuagga from "vue-quaggajs";
import Webcam from "webcamjs";
// import Webcam from "vue-webcam";
// import VueBarcode from "vue-barcode";
// import router from "../router";
import xlsx from "xlsx";
import Parse from "parse";
// register component 'v-quagga'
Vue.use(VueQuagga);

export default {
  name: "barcode",
  data() {
    return {
      aviso: "",
      email: "",
      name: "",
      password: "",
      validuser: false,
      // product: null,
      externalproduct: null,
      barcodeSearch: "",
      barcode: "",
      adviceQuanty: 0,
      active: true,
      totalProducts: 0,
      offer: 0,
      shopper: 0,
      esCustomIVU: false,
      dataInfo: null,
      multiple: false,
      usuarioFarmacia: {},
      backdrop: false,
      loader: false,
      customIVU: 0,
      esCustomIvu: false,
      description: "",
      user: Parse.User.current(),
      product: [],
      categories: [],
      productName: "",
      productPrice: 0,
      specialProductPrice: 0,
      productQuantity: 0,
      subCategory: "",
      picture: null,
      category: "",
      searchItem: "",
      subCat: null,
      modalStatus: null,
      objectData: null,
      originalPrice: null,
      isIvu: false,
      pictureUrl: null,
      isOnePercent: false,
      isSevenPercent: false,
      isElevenPercent: false,
      price: 0,
      quantity: 0,
      subCat2: null,
      image: null,
      oriPrice: null,
      pager: null,
      allData: null,
      esShopper: false,
      esOffer: false,
      shopperA: false,
      shopperB: false,
      shopperC: false,
      shopperD: false,
      shopperE: false,
      offerA: false,
      offerB: false,
      offerC: false,
      offerD: false,
      offerE: false,
      uploading: false,
      products: [],
      languaje: "",
      totalActiveProducts: 0,
      productStatus: "",
      totalIVU: 0,
      videoBarcode: false,
      readerSize: {
        width: 640,
        height: 480
      },
      detecteds: [],
      camaralocal: false,
      uripicture: "",
      snapshot: ""
    };
  },
  components: {
    // barcode: VueBarcode
  },
  watch: {
    barcodeSearch() {
      if (this.barcodeSearch !== "") {
        this.findByBarcode();
      } else {
        this.clearProductModal();
      }
    }
  },
  mounted() {
    this.languaje = navigator.language || navigator.userLanguage;
    this.languaje = this.languaje.substr(0, 2);
    this.product = null;
    this.externalproduct = null;
    this.getSubCategory();
    if (Parse.User.current()) {
      this.validuser = true;
      this.email = Parse.User.current().get("username");
      this.name = Parse.User.current().get("fullName");
    } else {
      this.validuser = false;
    }
  },
  methods: {
    takeSnapShot() {
      this.uploading = true;
      Webcam.snap(function(data_uri) {
        const base64Image = data_uri;
        const name = "photo.jpeg";
        const parseFile = new Parse.File(name, {
          base64: base64Image
        });
        parseFile.save().then(
          savedFile => {
            localStorage.setItem("capturepicture", JSON.stringify(savedFile));
            self.uploading = false;
          },
          err => {
            self.uploading = false;
            alert(err);
          }
        );
        document.getElementById("snapShot").innerHTML =
          '<img src="' + data_uri + '" width="70px" height="90px" /><br>';
      });
    },
    proccesscamaralocal() {
      if (this.camaralocal === false) {
        Webcam.set({
          width: 220,
          height: 190,
          image_format: "jpeg",
          jpeg_quality: 100,
          flip_horiz: false,
          constraints: {
            video: true,
            facingMode: "environment"
          }
        });
        Webcam.attach("#camera");
        this.camaralocal = true;
      } else {
        Webcam.reset();
        this.camaralocal = false;
      }
    },
    signOut() {
      Parse.User.logOut().then(() => {
        this.validuser = false;
      });
    },
    plusadviceQuanty() {
      if (this.adviceQuanty === 0) {
        this.adviceQuanty = 1;
      } else {
        this.adviceQuanty++;
      }
    },
    minusadviceQuanty() {
      if (this.adviceQuanty === 0) {
        this.adviceQuanty = 1;
      } else {
        this.adviceQuanty--;
      }
    },
    plusproductQuantity() {
      if (this.productQuantity === 0) {
        this.productQuantity = 1;
      } else {
        this.productQuantity++;
      }
    },
    minusproductQuantity() {
      if (this.productQuantity === 0) {
        this.productQuantity = 1;
      } else {
        this.productQuantity--;
      }
    },
    minusspecialProductPrice() {
      if (this.specialProductPrice === 0) {
        this.specialProductPrice = 1;
      } else {
        this.specialProductPrice--;
      }
    },
    plusspecialProductPrice() {
      if (this.specialProductPrice === 0) {
        this.specialProductPrice = 1;
      } else {
        this.specialProductPrice++;
      }
    },
    minusproductprice() {
      if (this.productPrice === 0) {
        this.productPrice = 1;
      } else {
        this.productPrice--;
      }
    },
    plusproductprice() {
      if (this.productPrice === 0) {
        this.productPrice = 1;
      } else {
        this.productPrice++;
      }
    },
    minusoriginalPrice() {
      if (this.originalPrice === 0) {
        this.originalPrice = 1;
      } else {
        this.originalPrice--;
      }
    },
    plusoriginalPrice() {
      if (this.originalPrice === 0) {
        this.originalPrice = 1;
      } else {
        this.originalPrice++;
      }
    },
    agregarproducto() {
      this.externalproduct = null;
    },
    signIn() {
      this.barcodeSearch = "";
      this.camaralocal = false;
      this.backdrop = true;
      this.loader = true;
      // let usuario = "";
      // let password = "";

      if (this.email === "" || this.password === "") {
        alert(
          this.languaje == "es"
            ? "¡Los campos son requeridos!"
            : "Fields are required!"
        );
      } else {
        Parse.User.logIn(this.email, this.password)
          .then(resp => {
            this.email = resp.get("username");
            this.name = resp.get("fullName");
            if (resp.get("isAdmin") === true) {
              this.validuser = true;
            } else if (resp.get("superAdmin") === true) {
              this.validuser = true;
            } else {
              this.validuser = false;
              Parse.User.logOut();
            }
          })
          .catch(err => {
            console.log(err);
            this.backdrop = false;
            this.loader = false;
            // let valid = false;
            // let storePassword = "";
            // let storeUser = "";
            const query = new Parse.Query("storeUsers");
            const query2 = new Parse.Query("_User");
            query.include("store");
            query.equalTo("email", this.email);
            query.equalTo("password", btoa(this.password));
            query.equalTo("status", true);
            query.equalTo("deleted", false);
            query.first().then(user => {
              query2.equalTo("objectId", user.get("store").get("employee").id);
              query2.first().then(uzer => {
                Parse.User.logIn(
                  uzer.get("username"),
                  atob(user.get("store").get("logkey"))
                )
                  .then(resp => {
                    if (resp.get("isAdmin") === true) {
                      this.validuser = true;
                    } else if (resp.get("superAdmin") === true) {
                      this.validuser = true;
                    } else {
                      this.validuser = false;
                      Parse.User.logOut();
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    alert(
                      this.languaje == "es"
                        ? "Correo Electronico o Contraseña incorrectos."
                        : "Email or Password incorrect."
                    );
                  });
              });
            });
          });
      }
    },
    findByBarcode() {
      this.clearProductModal();
      const query = new Parse.Query("Products");
      query.include("barcode");
      query.include("employee");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("barcode", this.barcodeSearch);

      query
        .first()
        .then(result => {
          this.aviso = "";
          this.product = result;
          this.objectData = this.product;
          this.esOffer = this.objectData.get("IsOffer");
          this.offer = this.objectData.get("offer");
          this.readOffer(this.offer);
          this.esShopper = this.objectData.get("esShopper");
          this.shopper = this.objectData.get("shopper");
          this.readShopper(this.shopper);
          this.name = this.objectData.get("Name");
          this.price = this.objectData.get("Price");
          this.subCat2 = this.objectData.get("SubCategory");
          this.image = this.objectData.get("picture").url();
          this.oriPrice = this.objectData.get("OriginalPrice");
          this.quantity = this.objectData.get("quantity");
          this.description = this.objectData.get("Description");
          this.adviceQuanty = this.objectData.get("productAdvice");
          this.barcode = this.objectData.get("barcode");
          this.productStatus =
            this.objectData.get("outOfStock") === false
              ? this.languaje === "es"
                ? "Disponible"
                : "Available"
              : this.languaje === "es"
              ? "Agotado"
              : "Out of Stock";

          if (this.objectData.attributes.ivuPercent === 0.07) {
            this.isSevenPercent = true;
          } else if (this.objectData.attributes.ivuPercent === 0.115) {
            this.isElevenPercent = true;
          } else if (this.objectData.attributes.ivuPercent === 0.01) {
            this.isOnePercent = true;
          } else {
            this.esCustomIvu = true;
            this.customIVU = (
              this.objectData.attributes.ivuPercent * 100
            ).toFixed(2);
          }
          this.specialProductPrice = this.objectData.attributes.preferenciaPrice;
          this.productPrice = this.price;
          this.originalPrice = this.oriPrice;
          this.isIvu = this.objectData.attributes.applyIvu;
          this.productName = this.name;
          this.subCategory = this.subCat2;
          this.picture = this.objectData.attributes.picture;
          this.productQuantity = this.quantity;
          this.totalIVU = (this.objectData.attributes.ivuPercent * 100).toFixed(
            2
          );
        })
        .catch(() => {
          this.clearProductModal();
          this.aviso =
            this.languaje == "es"
              ? "Producto No Encontrado"
              : "Product Not Found";
        });
    },
    proccessFile(event) {
      let selectedFile;
      selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      xlsx.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = xlsx.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = xlsx.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataInfo = rowObject;
          });
        };
      }
    },
    changeMultiple() {
      if (this.multiple) {
        this.multiple = false;
      } else {
        this.multiple = true;
      }
    },
    changeCustomIvu(data) {
      this.soloCustomIVU();
      this.customIVU = 0;
      this.esCustomIVU = data;
    },
    seeUserStore() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    },
    async getStoreId() {
      await Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      }).then(result => {
        this.pictureUrl = result.get("storePicture").url()
          ? result.get("storePicture").url()
          : "../assets/icons/coopharmaheart.png";
      });
    },
    cerrar() {
      this.shopperC = false;
      this.shopperB = false;
      this.shopperA = false;
      this.shopperE = false;
      this.shopperD = false;
      this.OfferC = false;
      this.OfferB = false;
      this.OfferA = false;
      this.OfferE = false;
      this.OfferD = false;
      this.desactivateBarcodeVideo();
    },
    onShopperA() {
      if (this.shopperA) {
        if (this.shopperC || this.shopperB || this.shopperE || this.shopperD) {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
        this.shopperB = false;
      }
      this.proccessShopper();
    },
    onShopperB() {
      if (this.shopperB) {
        if (this.shopperC || this.shopperE || this.shopperD) {
          this.shopperC = false;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
      }
      this.proccessShopper();
    },
    onShopperC() {
      if (this.shopperC) {
        if (this.shopperE || this.shopperD) {
          this.shopperC = true;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = false;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
        this.shopperD = false;
      }
      this.proccessShopper();
    },
    onShopperD() {
      if (this.shopperD) {
        if (this.shopperE) {
          this.shopperC = true;
          this.shopperB = true;
          this.shopperA = true;
          this.shopperE = false;
          this.shopperD = true;
        } else {
          this.shopperC = false;
          this.shopperB = false;
          this.shopperA = false;
          this.shopperE = false;
          this.shopperD = false;
        }
      } else {
        this.shopperD = true;
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
        this.shopperE = false;
      }
      this.proccessShopper();
    },
    onShopperE() {
      if (this.shopperE) {
        this.shopperE = false;
        this.shopperD = false;
        this.shopperC = false;
        this.shopperB = false;
        this.shopperA = false;
      } else {
        this.shopperE = true;
        this.shopperD = true;
        this.shopperC = true;
        this.shopperB = true;
        this.shopperA = true;
      }
      this.proccessShopper();
    },
    onOfferA() {
      if (this.offerA) {
        if (this.offerC || this.offerB || this.offerE || this.offerD) {
          this.offerC = false;
          this.offerB = false;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
        this.offerB = false;
      }
      this.proccessOffer();
    },
    onOfferB() {
      if (this.offerB) {
        if (this.offerC || this.offerE || this.offerD) {
          this.offerC = false;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
      }
      this.proccessOffer();
    },
    onOfferC() {
      if (this.offerC) {
        if (this.offerE || this.offerD) {
          this.offerC = true;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = false;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
        this.offerD = false;
      }
      this.proccessOffer();
    },
    onOfferD() {
      if (this.offerD) {
        if (this.offerE) {
          this.offerC = true;
          this.offerB = true;
          this.offerA = true;
          this.offerE = false;
          this.offerD = true;
        } else {
          this.offerC = false;
          this.offerB = false;
          this.offerA = false;
          this.offerE = false;
          this.offerD = false;
        }
      } else {
        this.offerD = true;
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
        this.offerE = false;
      }
      this.proccessOffer();
    },
    onOfferE() {
      if (this.offerE) {
        this.offerE = false;
        this.offerD = false;
        this.offerC = false;
        this.offerB = false;
        this.offerA = false;
      } else {
        this.offerE = true;
        this.offerD = true;
        this.offerC = true;
        this.offerB = true;
        this.offerA = true;
      }
      this.proccessOffer();
    },
    getStateProducts(state) {
      this.active = state;
      this.getProducts();
      this.getProductsActiveTotal();
    },
    getProductsFilteredBy() {
      let query = new Parse.Query("Products");
      query.include("storeId");
      query.include("categoryId");
      query.ascending("Name");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.fullText("Name", this.dataToSearch, { diacriticSensitive: false });
      query.find().then(products => {
        this.products = products;
      });
    },
    getProductsCategorias() {
      let query = new Parse.Query("Products");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.count().then(total => {
        this.totalProducts = total;
      });
    },
    getProductsActiveTotal() {
      let query = new Parse.Query("Products");
      query.equalTo("employee", Parse.User.current());
      query.equalTo("SubCategory", this.dataToSearchByCategory);
      query.equalTo("outOfStock", !this.active);
      query.count().then(total => {
        this.totalActiveProducts = total;
      });
    },

    getProducts() {
      let query = new Parse.Query("Products");
      query.include("storeId");
      query.include("categoryId");
      query.include("outOfStock");
      query.ascending("Name");
      query.equalTo("outOfStock", !this.active);
      query.equalTo("employee", Parse.User.current());
      query.find().then(products => {
        this.products = products;
      });
    },
    getSubCategory() {
      Parse.Cloud.run("getSubCategory", {
        userId: Parse.User.current().id
      }).then(result => {
        this.subCat = result;
      });
    },
    getImage(data) {
      let image;
      if (data != null && data.url) {
        image = data.url();
        return image;
      } else {
        return "";
      }
    },
    outOfStock(dat) {
      let state = true;
      dat.set("outOfStock", state);

      dat.save().then(() => {
        alert(
          this.languaje == "es"
            ? "Producto Acabado, no disponible para ordenes"
            : "Out of Stock Product, it is not available for orders"
        );
        // this.$swal({
        //   title: this.languaje == "es"?"Atención":"Attention",
        //   text: this.languaje == "es"?"Producto Acabado, no disponible para ordenes":"Out of Stock Product, it is not available for orders",
        //   type: "info",
        // });
        this.getStateProducts(state);
      });
    },
    activateOutOfStock(dat) {
      let state = false;
      dat.set("outOfStock", state);
      dat.save().then(() => {
        alert(
          this.languaje == "es"
            ? "Producto Disponible, se encuentra disponible para ordenes"
            : "Available Product, it is available for orders"
        );
        // this.$swal({
        //   title: this.languaje == "es"?"Atención":"Attention",
        //   text: this.languaje == "es"?"Producto Disponible, se encuentra disponible para ordenes":"Available Product, it is available for orders",
        //   type: "info",
        // });
        this.getStateProducts(state);
      });
    },
    applyIvu(dat) {
      dat.set("applyIvu", true);
      dat.save().then(() => {
        this.gettingStores();
      });
    },
    notApplyIvu(dat) {
      dat.set("applyIvu", false);
      dat.save().then(() => {
        this.gettingStores();
      });
    },
    proccessShopper() {
      this.shopper = 0;
      if (this.esShopper) {
        if (
          this.shopperA &&
          !this.shopperB &&
          !this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 1;
        } else if (
          this.shopperA &&
          this.shopperB &&
          !this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 2;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          !this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 3;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          this.shopperD &&
          !this.shopperE
        ) {
          this.shopper = 4;
        } else if (
          this.shopperA &&
          this.shopperB &&
          this.shopperC &&
          this.shopperD &&
          this.shopperE
        ) {
          this.shopper = 5;
        } else {
          this.shopper = 0;
        }
      } else {
        this.shopper = 0;
      }
    },
    proccessOffer() {
      this.offer = 0;
      if (this.esOffer) {
        if (
          this.offerA &&
          !this.offerB &&
          !this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 1;
        } else if (
          this.offerA &&
          this.offerB &&
          !this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 2;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          !this.offerD &&
          !this.offerE
        ) {
          this.offer = 3;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          this.offerD &&
          !this.offerE
        ) {
          this.offer = 4;
        } else if (
          this.offerA &&
          this.offerB &&
          this.offerC &&
          this.offerD &&
          this.offerE
        ) {
          this.offer = 5;
        } else {
          this.offer = 0;
        }
      } else {
        this.offer = 0;
      }
    },
    readOffer(offer) {
      switch (offer) {
        case 0: {
          this.offerA = false;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 1: {
          this.offerA = true;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 2: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 3: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = false;
          this.offerE = false;
          break;
        }
        case 4: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = true;
          this.offerE = false;
          break;
        }
        case 5: {
          this.offerA = true;
          this.offerB = true;
          this.offerC = true;
          this.offerD = true;
          this.offerE = true;
          break;
        }
        default: {
          this.offerA = false;
          this.offerB = false;
          this.offerC = false;
          this.offerD = false;
          this.offerE = false;
          break;
        }
      }
    },
    readShopper(shopper) {
      switch (shopper) {
        case 0: {
          this.shopperA = false;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 1: {
          this.shopperA = true;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 2: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 3: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
        case 4: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = true;
          this.shopperE = false;
          break;
        }
        case 5: {
          this.shopperA = true;
          this.shopperB = true;
          this.shopperC = true;
          this.shopperD = true;
          this.shopperE = true;
          break;
        }
        default: {
          this.shopperA = false;
          this.shopperB = false;
          this.shopperC = false;
          this.shopperD = false;
          this.shopperE = false;
          break;
        }
      }
    },
    noAplicarIVU() {
      if (this.isIvu) {
        this.esCustomIvu = false;
        this.customIVU = 0;
        this.isOnePercent = false;
        this.isSevenPercent = false;
        this.isElevenPercent = false;
      }
    },
    soloCustomIVU() {
      this.customIVU = 0;
      this.isElevenPercent = false;
      this.isSevenPercent = false;
      this.isOnePercent = false;
    },
    soloOneIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isElevenPercent = false;
      this.isSevenPercent = false;
    },
    soloSevenIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isElevenPercent = false;
      this.isOnePercent = false;
    },
    soloElevenIVU() {
      this.customIVU = 0;
      this.esCustomIvu = false;
      this.isOnePercent = false;
      this.isSevenPercent = false;
    },
    create() {
      this.clearProductModal();
      this.modalStatus = false;
    },
    clearProductModal() {
      this.desactivateBarcodeVideo();
      this.adviceQuanty = 0;
      this.product = null;
      this.barcode = this.barcodeSearch;
      this.description = "";
      this.objectData = null;
      this.productName = "";
      this.subCategory = "OTC";
      this.productPrice = 1;
      this.originalPrice = 1;
      this.specialProductPrice = 1;
      this.picture = null;
      this.originalPicture = "";
      this.isIvu = true;
      this.productQuantity = 1;
      this.esCustomIvu = false;
      this.customIVU = 1;
      this.isOnePercent = false;
      this.isSevenPercent = false;
      this.isElevenPercent = false;
      this.esCustomIvu = false;
      this.esShopper = false;
      this.shopper = 0;
      this.esOffer = false;
      this.offer = 0;
      this.shopperA = false;
      this.shopperB = false;
      this.shopperC = false;
      this.shopperD = false;
      this.shopperE = false;
      this.offerA = false;
      this.offerB = false;
      this.offerC = false;
      this.offerD = false;
      this.offerE = false;
    },
    changeOffer() {
      this.offerA = false;
      this.offerB = false;
      this.offerC = false;
      this.offerD = false;
      this.offerE = false;
    },
    changeShopper() {
      this.shopperA = false;
      this.shopperB = false;
      this.shopperC = false;
      this.shopperD = false;
      this.shopperE = false;
    },
    agregarProductos() {
      this.aviso = "";
      this.modalStatus = false;
      this.clearProductModal();
      this.getSubCategory();
    },
    saveChange() {
      this.backdrop = true;
      setTimeout(() => {
        this.loader = true;
      }, 200);
      this.objectData.set("Name", this.productName);
      this.objectData.set("Price", this.productPrice.toString());
      this.objectData.set("OriginalPrice", this.originalPrice.toString());
      this.objectData.set("SubCategory", this.subCategory);
      this.objectData.set("Description", this.description);
      this.objectData.set("picture", this.picture);
      this.objectData.set("applyIvu", !this.isIvu);
      this.objectData.set("quantity", this.productQuantity.toString());
      this.objectData.set("shopper", this.shopper);
      this.objectData.set("esShopper", this.esShopper);
      this.objectData.set("offer", this.offer);
      this.objectData.set("IsOffer", this.esOffer);
      this.objectData.set(
        "preferenciaPrice",
        this.specialProductPrice.toString()
      );
      this.objectData.set("productAdvice", parseInt(this.adviceQuanty));
      this.objectData.set("barcode", this.barcode);
      if (!this.isIvu) {
        if (
          !this.isOnePercent &&
          !this.isSevenPercent &&
          !this.isElevenPercent &&
          !this.esCustomIvu
        ) {
          alert(
            this.languaje == "es"
              ? "Tiene que escoger un porcentaje"
              : "You have to select a porcentage"
          );
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
      }
      if (
        (this.isOnePercent && this.isSevenPercent) ||
        (this.isOnePercent && this.isElevenPercent) ||
        (this.isElevenPercent && this.isSevenPercent) ||
        (this.isElevenPercent && this.esCustomIvu) ||
        (this.isOnePercent && this.esCustomIvu)
      ) {
        alert(
          this.languaje == "es"
            ? "Tiene que escoger una sola opcion de IVU."
            : "You have to select only an IVU option"
        );
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      } else {
        if (this.isOnePercent) {
          this.objectData.set("ivuPercent", 0.01);
        }
        if (this.isSevenPercent) {
          this.objectData.set("ivuPercent", 0.07);
        }
        if (this.isElevenPercent) {
          this.objectData.set("ivuPercent", 0.115);
        }
        if (this.esCustomIvu) {
          this.objectData.set("ivuPercent", this.customIVU / 100);
        }

        if (
          this.productPrice !== 0 &&
          this.originalPrice !== 0 &&
          this.productQuantity !== 0
        ) {
          this.loader = false;
          this.backdrop = false;
          this.objectData.save().then(() => {
            this.loader = false;
            this.backdrop = false;
            document.getElementById("exampleModalCenter").click();
            this.clearProductModal();
            // this.gettingStores();
            alert(
              this.languaje == "es"
                ? "Producto actualizado correctamente"
                : "Product updated correctly"
            );
            // this.$swal({
            //   title: this.languaje == "es"?"Exito":"Success",
            //   text: this.languaje == "es"?"Producto actualizado correctamente":"Product updated correctly",
            //   type: "success",
            // });
          });
        } else {
          this.loader = false;
          this.backdrop = false;
          alert(
            this.languaje == "es"
              ? "No deben haber precios en cero"
              : "It must not have prices in cero"
          );
          // this.$swal({
          //   title: this.languaje == "es"?"Atención":"Atention",
          //   text: this.languaje == "es"?"No deben haber precios en cero":"It must not have prices in cero",
          //   type: "info",
          // });
        }
      }
    },
    createProducts() {
      if (localStorage.getItem("capturepicture")) {
        this.picture = JSON.parse(localStorage.getItem("capturepicture"));
      }
      this.backdrop = true;
      setTimeout(() => {
        this.loader = true;
      }, 200);
      let percent = 0;
      if (
        this.productName === null ||
        this.productName === "" ||
        this.productPrice === null ||
        this.productPrice === 0 ||
        this.subCategory === null ||
        this.subCategory === "" ||
        this.picture === null ||
        this.originalPrice === null ||
        this.productQuantity === null ||
        this.productQuantity === 0 ||
        this.barcode === "" ||
        this.barcode === null
      ) {
        alert(
          this.languaje == "es"
            ? "Todos los campos son requeridos"
            : "All fields are required"
        );
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      }
      if (!this.isIvu) {
        if (
          !this.isOnePercent &&
          !this.isSevenPercent &&
          !this.isElevenPercent &&
          !this.esCustomIvu
        ) {
          alert(
            this.languaje == "es"
              ? "Tiene que escoger un porcentaje"
              : "You have to select a porcentage"
          );
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
      }
      if (
        this.isIvu &&
        (this.isOnePercent ||
          this.isSevenPercent ||
          this.isElevenPercent ||
          this.esCustomIvu)
      ) {
        alert(
          this.languaje == "es"
            ? "No puede tener un IVU con excento junto"
            : "It is not posible to have excent IVU together"
        );
        setTimeout(() => {
          this.loader = false;
          this.backdrop = false;
        }, 200);
        return;
      }
      Parse.Cloud.run("getStoreId", {
        userId: Parse.User.current().id
      }).then(result => {
        this.category = result.get("subCategories");
        if (
          (this.isOnePercent && this.isSevenPercent) ||
          (this.isOnePercent && this.isElevenPercent) ||
          (this.isOnePercent && this.esCustomIvu) ||
          (this.isElevenPercent && this.esCustomIvu) ||
          (this.isSevenPercent && this.esCustomIvu)
        ) {
          alert(
            this.languaje == "es"
              ? "Tiene que escoger una sola opcion de ivu."
              : "You have to select only an IVU option"
          );
          setTimeout(() => {
            this.loader = false;
            this.backdrop = false;
          }, 200);
          return;
        }
        if (this.isOnePercent) {
          percent = 0.01;
        }
        if (this.isSevenPercent) {
          percent = 0.07;
        }
        if (this.isElevenPercent) {
          percent = 0.115;
        }
        if (this.esCustomIvu) {
          percent = this.customIVU / 100;
        }
        if (
          this.productPrice !== 0 &&
          this.originalPrice !== 0 &&
          this.productQuantity !== 0
        ) {
          Parse.Cloud.run("createProduct", {
            prodPrice: this.productPrice.toString(),
            preferenciaPrice: this.specialProductPrice.toString(),
            originalPrice: this.originalPrice.toString(),
            prodCategory: this.category,
            prodDescription: this.description,
            prodName: this.productName,
            userId: Parse.User.current().id,
            storeId: result.id,
            subCategory: this.subCategory,
            picture: this.picture,
            ivu: !this.isIvu,
            percent: percent,
            quantity: this.productQuantity.toString(),
            shopper: this.shopper,
            esShopper: this.esShopper,
            offer: this.offer,
            esOffer: this.esOffer,
            barcode: this.barcode,
            productAdvice: parseInt(this.adviceQuanty)
          }).then(() => {
            document.getElementById("exampleModalCenter").click();
            this.clearProductModal();
            this.loader = false;
            this.backdrop = false;
            alert(
              this.languaje == "es"
                ? "Producto creado correctamente"
                : "Product created correctly"
            );
            this.proccesscamaralocal();
            // this.$swal({
            //   title: this.languaje == "es"?"Exito":"Success",
            //   text: this.languaje == "es"?"Producto creado correctamente":"Product created correctly",
            //   type: "success",
            // });
          });
        } else {
          this.loader = false;
          this.backdrop = false;
          alert(
            this.languaje == "es"
              ? "No deben haber precios en cero"
              : "It must not have prices in cero"
          );
          // this.$swal({
          //   title: this.languaje == "es"?"Atención":"Atention",
          //   text: this.languaje == "es"?"No deben haber precios en cero":"It must not have prices in cero",
          //   type: "info",
          // });
        }
      });
    },
    onSelectedFile(event) {
      if (event.path[0].files[0].size < 2097152) {
        // let self = this;
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.Main(toBase64);
      } else {
        alert(
          this.languaje == "es"
            ? "Esta imagen no es posible procesarla, elija otra por favor"
            : "This picture is not possible to proccess it, please select another one"
        );
        // this.$swal ({
        //   title: this.languaje == "es"?"Atencíon":"Attention",
        //   text: this.languaje == "es"?"Esta imagen no es posible procesarla, elija otra por favor":"This picture is not possible to proccess it, please select another one",
        //   type: "info",
        // });
      }
    },
    Main: async function(toBase64) {
      const file = document.querySelector("camarabtn").files[0];
      this.uploading = true;
      this.picture = await toBase64(file);
      const base64Image = this.picture;
      const name = "photo.jpeg";
      const parseFile = new Parse.File(name, {
        base64: base64Image
      });
      parseFile.save().then(
        savedFile => {
          this.picture = savedFile;
          this.uploading = false;
        },
        err => {
          this.uploading = false;
          alert(err);
        }
      );
    },
    onFileSelected(event) {
      alert(event.path[0].files[0].size);

      if (event.path[0].files[0].size < 2097152) {
        // let self = this;
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.Main2(toBase64);
      } else {
        alert(
          this.languaje == "es"
            ? "Esta imagen no es posible procesarla, elija otra por favor"
            : "This picture is not possible to proccess it, please select another one"
        );
        // this.$swal ({
        //   title: this.languaje == "es"?"Atencíon":"Attention",
        //   text: this.languaje == "es"?"Esta imagen no es posible procesarla, elija otra por favor":"This picture is not possible to proccess it, please select another one",
        //   type: "info",
        // });
      }
    },
    Main2: async function(toBase64) {
      const file = document.querySelector("#inputGroupFile02").files[0];
      this.uploading = true;
      this.picture = await toBase64(file);
      const base64Image = this.picture;
      const name = "photo.jpeg";
      const parseFile = new Parse.File(name, {
        base64: base64Image
      });
      parseFile.save().then(
        savedFile => {
          this.picture = savedFile;
          this.uploading = false;
        },
        err => {
          this.uploading = false;
          alert(err);
        }
      );
    },
    logIt(data) {
      this.barcodeSearch = data.codeResult.code;
      this.desactivateBarcodeVideo();
    },
    activateBarcodeVideo() {
      this.barcodeSearch = "";
      this.videoBarcode = true;
    },
    desactivateBarcodeVideo() {
      this.videoBarcode = false;
    }
  }
};
</script>
<style scoped>
.asteric {
  size: 10px;
  color: #a50000ad;
}
.logoButton {
  width: 10%;
  height: 10%;
  border-style: none;
}
.bar {
  background-color: #e0e0e0;
  padding: 20px;
  width: 100%;
}
.productImage {
  width: 100%;
  height: 300px;
  border-radius: 40px;
  object-fit: contain;
  object-position: center;
  margin: -10px;
}
.space {
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 5px;
}
.star {
  border: solid #4e4e4e 1px;
  color: #4e4e4e;
  font-size: 40px;
}

.star:hover {
  background-color: #d3ae09;
}

.selected {
  background-color: #f3c600;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1e1e1e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1e1e1e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-file-input {
  border-top: 0 !important;
}
</style>
